<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import orderConfig from '@/orderConfig'
import { orderStateList } from '@/utils/textFile'

export default {
  name: 'offlinePay',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/order/offline/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号'
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName'
        },
        {
          name: '用户名',
          type: 'input',
          key: 'buyName'
        },
        {
          name: '电话',
          type: 'input',
          key: 'buyPhone',
          placeholder: '请输入用户电话'
        }
      ]
    },
    // 获取选中订单详情
    getDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/api/agr/order/detail?id=${id}`)
          .then(result => {
            resolve(result.data)
          })
          .catch(e => reject && reject(e))
      })
    },
    // 退款事件
    onRefund({ records, item }) {
      let that = this
      // type手动传参 告诉当前订单类型
      orderConfig.refund({
        data: records,
        params: { data: { ...records, type: 'eatDrink' } },
        executeFun: [
          // 基础
          orderConfig.base,
          // 优惠券
          orderConfig.coupons,
          // 售后
          orderConfig.afterProcessing,
          // 记录
          orderConfig.record
        ],
        success({ data, setHidden }) {
          api.order.refund
            .call(that, {
              url: '/agr/order/refund',
              params: { ...item, orderId: item.orderId, ...data }
            })
            .then(setHidden)
        }
      })
    },
    onClose(data) {
      orderConfig.close({
        data,
        params: {
          data
        },
        executeFun: [
          // 表格数据
          orderConfig.table,
          // 基础信息
          orderConfig.base,
          // 记录
          orderConfig.record
        ],
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id }
            })
            .then(setHidden)
        }
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          type: 'lt-200',
          isId: true
        },
        {
          dataIndex: 'createTime',
          title: '支付时间',
          align: 'left'
        },
        {
          dataIndex: 'totalAmount',
          title: '支付总额',
          align: 'left'
        },
        {
          dataIndex: 'couponAmount',
          title: '优惠金额',
          align: 'left'
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额（元）',
          align: 'left'
        },
        {
          dataIndex: 'payType',
          title: '付款方式',
          align: 'left',
          filters: [
            {
              text: '未支付',
              value: '0'
            },
            {
              text: '支付宝',
              value: '1'
            },
            {
              text: '微信',
              value: '2'
            },
            {
              text: '农行支付',
              value: '3'
            },
            {
              text: '0元付',
              value: '4'
            }
          ],
          filterMultiple: false,
          customRender: (text, records) => {
            return { '1': '支付宝', '2': '微信', '3': '农行支付', '4': '0元付' }[text] || '未支付'
          },
          onExport: (text, records) => {
            return { '1': '支付宝', '2': '微信', '3': '农行支付', '4': '0元付' }[text] || '未支付'
          }
        },
        {
          dataIndex: 'shopName',
          title: '店铺名称',
          align: 'left'
        },
        {
          dataIndex: 'buyName',
          title: '用户名',
          align: 'left'
        },
        {
          dataIndex: 'buyPhone',
          title: '电话',
          align: 'left',
          width: 200
        },
        {
          dataIndex: 'status',
          title: '支付状态',
          type: 'badge',
          align: 'center',
          onExport: text => {
            return {
              '0': '未支付',
              '5': '已支付',
              '6': '已关闭'
            }[text]
          },
          filters: [
            {
              text: '未支付',
              value: '0'
            },
            {
              text: '已支付',
              value: '5'
            },
            {
              text: '已关闭',
              value: '6'
            }
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name:
                {
                  '0': '未支付',
                  '5': '已支付',
                  '6': '已关闭'
                }[data] || '',
              color:
                {
                  '0': 'orange',
                  '5': 'green',
                  '6': 'red'
                }[data] || ''
            }
          }
        },
        {
          dataIndex: 'jsStatus',
          title: '结算状态',
          type: 'badge',
          filters: [
            {
              text: '已结算',
              value: '1'
            },
            {
              text: '未结算',
              value: '0'
            }
          ],
          filterMultiple: false,
          onExport: records => {
            return ['未结算', '二级商户', '结算失败', '结算异常'][records]
          },
          render(data, records) {
            return {
              showDot: data ? true : false,
              name: records.jsType == '1' ?
                '手动' : 
                ({
                  '0': '未结算',
                  '1': '二级商户',
                  '2': '结算失败',
                  '3': '结算异常'
                }[data] || ''),
              color:
                {
                  '0': '#e6ebf1',
                  '1': '#ff9901',
                  '2': '#ff9901',
                  '3': '#ed4014'
                }[data] || ''
            }
          }
        },
        {
          dataIndex: 'cashOutStatus',
          title: '出金状态',
          type: 'badge',
          align: 'center',
          filterMultiple: false,
          onExport: records => {
            return ['未出金', '已出金', '出金失败', '出金异常'][records]
          },
          render(data, records) {
            return {
              showDot: data ? true : false,
              name: records.jsType == '1' ?
                '' : 
                ({
                  '0': '未出金',
                  '1': '已出金',
                  '2': '出金失败',
                  '3': '出金异常',
                }[data] || ''),
              color:
                {
                  '0': '#e6ebf1',
                  '1': '#ff9901',
                  '2': '#ed4014',
                  '3': '#ed4014'
                }[data] || ''
            }
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '主动退款',
                type: 'pop',
                popTitle: '确认主动退款吗?',
                display: records.status == '5' && (records.jsStatus == '0' || records.jsStatus == '2'),
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/order/offline/refund?id=${records.id}`,
                    // params: { id: records.id }
                  })
                }
              }
            ].filter(e => e.display)
          }
        }
      ]
    },
    // 批量删除
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map(e => e.id) }
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete
            }
          ]
        }
      ]
    },
    onRowSelect(records) {
      const ext = [
        // 表格
        orderConfig.table,
        // 退款信息
        orderConfig.retuenInfo,
        // 基本信息
        orderConfig.base,
        // 优惠券
        orderConfig.coupons,
        // 记录
        orderConfig.record
      ]
      this.getDetailData(records.id).then(e => {
        orderConfig.open({
          data: e,
          params: { data: e },
          executeFun: ext
        })
      })
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        // onSelectRow={this.onRowSelect}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
</style>
